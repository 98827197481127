@import 'common/modern.css';
@import 'common/core.css';


.sidebar .sidebar-titleSection {
  font-weight: 500;
  color: #ffffff;
}

.sidebar-user {
  font-size: x-large;
}

.sidebar .icon-logo {
  width: 12%;
}

.add-dependent .modal-body {
  max-height: 550px;
  overflow-y: auto;
}

.add-dependent {
  max-width: 1000px;
  /* width: 100%; */
}

.password-show.form-control.is-invalid {
  background-image: none !important;
}

::-ms-reveal {
  display: none !important;
}