/* .sign-up {
  position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
} */
.sign-up .card {
  margin-bottom: 0px;
}

.image-wrapper {
  padding: 1rem 0 2rem;
}

.react-select-container .react-select__value-container {
  height: calc(2.25rem + 1px);
}

.file-upload-row {
  padding: 1.875rem 0;
}

.prof-pic-wrapper {
  border: 1px solid #ced4da;
  min-height: 150px;
  border-radius: 0.5rem;
}

.prof-pic {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
}

.i-agree {
  margin-top: 2em;
  margin-bottom: 2em;
}

@media screen and (max-width: 768px) {
  .sign-up {
    position: initial;
    -webkit-transform: inherit;
    transform: inherit;
    left: auto;
    top: auto;
  }
}

.password_tooltip {
  margin-left: 110% !important;
  margin-top: -38% !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.move-right {
  margin-left: 140% !important;
  margin-top: -33% !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.sign-up-dob-input .react-datepicker__input-container input {
  width: 100% !important;
}

.sign-up-dob-input .react-datepicker__input-container input.red-border {
  border-color: #F26666 !important;
}

.radioBtn .form-control.is-invalid {
  background-image: none !important;
}