.card-body {
  padding: 0.75rem 1.25rem;
}

.care-info-collapse {
  padding: 15px 0;
}

.care-profile-alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  /* color: #495057; */
  background-color: #f1f1f1;
  border-color: #d6d8db;
}

.care-info-header-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.care-info-header {
  margin-left: 30px;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 20px;
}

.input-group-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.sidebar-brand {
  display: flex;
  justify-content: center;
  background-color: #38a6d2;
}

.sidebar-brand:hover {
  background-color: #38a6d2;
}

.vitals_info_form p.down_label {
  text-align: center;
  margin-top: -15px;
  font-size: 12px;
  margin-bottom: 0px;
  color: steelblue;
}

.vitals_info_form, .allergy_info, .medication_info {
  display: inline-block;
  margin-bottom: 3px;
  text-align: left !important;
}

.vitals_info_form {
  text-align: left;
}

.pull-right {
  float: right;
}

.react-select-container .react-select__value-container {
  height: inherit !important;
}

.react-datepicker__input-container input {
  display: block;
  width: 230px !important;
  height: calc(1.90625rem + 2px);
  padding: 0.25rem 0.7rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da !important;
  border-radius: 0.2rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

input {
  max-width: 100%;
}

.CareInfo {
  height: 100vh;
  overflow: scroll;
}

.CareInfo::-webkit-scrollbar {
  display: none;
}

.CareInfo .table th,
.CareInfo .table td {
  padding: 5px;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
}

.VitalsInfo {
  position: relative;
}

.VitalsTableExpands span {
  background: #e8f5ef;
  display: inline-block;
  padding: 6px 10px;
  line-height: 12px;
  font-size: 12px;
  color: #116f45;
  margin-right: 10px;
  height: 24px;
  margin-top: 10px;
  min-width: 30%;
  border-radius: 3px;
}

.CareInfo .spinner-border {
  position: absolute;
  left: 45%;
  top: 45%;
  z-index: 99;
}

.CareInfo .react-bootstrap-table .row-expansion-style {
  padding: 8px;
  padding-top: 2px;
}

.badge {
  cursor: pointer;
}

.modal-content label {
  font-size: 12px;
}

.CareInfo .modal-body {
  margin: 0px !important;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.bordered .react-datepicker__input-container input, .react-datepicker__input-container:focus input, .input-wrapper .form-control, .input-wrapper .form-control:focus, .custom-select {
  border-color: #ced4da !important;
}

.RecordDate.form-group label {
  width: 15%;
}

.RecordDate.form-group .react-datepicker-wrapper {
  width: 50%;
  display: inline-block;
}

.care-info-header-container button {
  position: absolute;
  right: 20px;
}

span.requiredcustom {
  font-size: 10px;
  color: red;
  display: block;
}

.care-info-header {
  cursor: pointer;
}

table svg.mr-2 {
  margin-right: 0px !important;
}

.outline-primary {
  border-color: #22b8f3;

  color: #0798d1;

  background-color: #fff;
}

.outline-primary:hover {
  background-color: #04a4e4f3;
    border-color: #22b8f3;
  color: #fff;


}

.delete-table-row {
  margin-bottom: 0px;
  padding: 0.2rem 0.7rem;
}

.VitalsModal, .AllergyModal, .MedicationModal, .PharmaciesModal, .PhysiciansModal {
  margin-top: 0px !important;
}

.ModalHeader {
  text-align: center;
  margin-bottom: 15px;
}

span.mandatory-marker {
  color: red;
  font-size: 12px;
}

.pharmacy_favourite {
  cursor: pointer;
  font-size: 24px;
}

@media screen and (max-width: 768px) {
  .care-card {
    overflow-x: scroll;
  }
  .care-card .react-bootstrap-table {
    width: 676px;
  }
  .care-card .react-bootstrap-table .delete-table-row {
    margin-bottom: 0px;
    padding: 0.2rem 6px;
  }
}

.pharmacy_edit_button, .physician_edit_button {
  height: 30px;
  line-height: 10px;
  margin-right: 1em;
}

.CareTeamTableExpands span {
  background: #e8f5ef;
  display: inline-block;
  padding: 6px 10px;
  line-height: 12px;
  font-size: 12px;
  color: #116f45;
  margin-right: 10px;
  height: 24px;
  margin-top: 10px;
  min-width: 30%;
  border-radius: 3px;
}

.delete-button {
  color: grey;
  border-color: grey;
  margin-bottom: 0px;
  padding: 0.2rem 0.7rem;
}

.delete-button:hover {
  color: white !important;
  background-color: #f7323c !important;
  border-color: #f7323c;
}

.delete-button-vital {
  color: grey;
  border-color: grey;
  margin-bottom: 5px;
  padding: 0.2rem 0.2rem;
}

.delete-button-vital:hover {
  color: white !important;
  background-color: #f7323c !important;
  border-color: #f7323c;
}

.edit-button-vital {
  color: grey;
  border-color: grey;
  margin-bottom: 5px;
  padding: 0.2rem 0.2rem;
  background-color: white;
}

.edit-button-vital:hover {
  color: white !important;
  background-color: #46b7ee !important;
  border-color: #46b7ee;
}

.pharmacy-badge {
  font-size: 1em;
  cursor: alias;
}

@media screen and (max-width: 390px) {
  .care-info-header {
    margin-left: 10px;
  }
  .care-info-header-container .btn {
    width: 137px;
    padding: 2px 2px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.no-data-found-card {
  padding: 2em;
  margin: auto;
}

.advanced-search-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.advanced-search-table-height {
  min-height: auto;
  max-height: 40vh;
  overflow: scroll;
  white-space: normal;
  word-break: break-all;
}

.advanced-search-modal-footer {
  justify-content: center;
  width: 100%;
}

.advanced-search-radio-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 1em;
}

.adv-search-background-card {
  background-color: #f5f5f5;
  padding: 1em;
}
@-webkit-keyframes slideIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 100;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 100;
  }
}

.advanced-search-table {
  -webkit-animation: slideIn 1.5s ease;
  animation: slideIn 1.5s ease;
  word-break: break-word;
}

.advanced-search-table .table tr td:nth-child(1),
.advanced-search-table .table tr th:nth-child(1) {
  text-align: center;
  width: 68px;
}

.advanced-search-table :nth-child(2) {
  width: 150px;
}

.advanced-search-table :nth-child(4) {
  width: 200px;
}

.modal-close{
  cursor: pointer;
  position: absolute;
    right: 5px;
    top: -3px;
    color: #46b7ee;
    z-index: 99;
    font-size: 24px;
}
.modal-border {
  border-bottom: 1px solid #dee2e6;
}
.font-style {
  font-size:larger;
}
.ModalHeader{
  text-align: center;
 /*  margin-bottom: 15px; */
  
}