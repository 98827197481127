.nav-bar-profile-pic {
    /* position: absolute;
    right: 3.7em;
    top: .5em; */
}

.disable-scrollbars::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* Chrome/Safari/Webkit */
}

.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
}

.button-width {
    width: 100px;
}

.object-cover{
    object-fit: cover;
}

.object-contain{
    object-fit: contain;
}