.MuiInput-underline-20:after {
  border-bottom: 2px solid #46b7ee !important;
}

.sub-nav-item-padding li {
  padding: 1em;
}

.profile-alert {
  padding: 1em;
  font-weight: 500;
  margin-top: 0.5em;
  margin-bottom: -10px;

}

.profile-photo-error {
  border: solid 2px #fe5d69;
  border-radius: 5px;
  margin: 0 0 1.5em 0.86em;
  padding: 1em;
}

.profile-photo {
  margin: 0 0 1em 0;
  padding: 1em;
}

.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 6;
  display: table;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(0, 199, 137, 0.05);
  border-top-color: #00c789;
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

.save-btn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.save-btn-wrapper button {
  margin: 0 .5em;
}

.mobile-field-verified-icon {
  color: #46b7ee;
}

.mobile-field-notVerified-warning-icon {
  color: #f2b51d;
}

.link-color {
  color: #ffffff;
}

.link-color:hover {
  color: #0056b3;
  cursor: pointer;
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  .profile-save-btn {
    margin-top: 1rem;
    text-align: center;
  }

  .mt-2.mb-2.remove-margins {
    margin: 0 !important;
  }

  .reduce-padd .form-group {
    margin-bottom: 7px;
  }

  .reduce-padd>label {
    margin-bottom: 5px;
  }

  .gender-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.version-number {
  font-size: 0.7em;
  margin-left: 1em;
  margin-bottom: 1em;
  color: grey;
  float: right;
  position: absolute;
  bottom: 0;
}

.required {
  color: red;
}

.Address-GeoLocation {
  width: 100% !important;
  margin-top: 0.25rem !important;
  font-size: 80% !important;
  color: #F26666 !important;
}

.profile-date {
  width: 278px !important;
}

.profile-picture {
  width: 100%;
  justify-content: center;
  margin: -16px 0 12px 0;
}